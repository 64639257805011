.App {
  text-align: center;
}

.Slider {
  position: relative;
}

.Slide {
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.75);
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  font: bold 6em/1 sans-serif;
}

.Progress {
  position: fixed;
  z-index: 10;
  top: 20%;
  bottom: 20%;
  left: 2em;
  width: 6px;
  border-radius: 3px;
  background-color: white;
}

.ProgressDot {
  position: absolute;
  width: 12px;
  height: 12px;
  left: 50%;
  border-radius: 6px;
  transform: translate(-50%, -50%);
}

.ContentProgress {
  position: absolute;
  top: 0;
  left: 0;
  height: 10px;
  background-color: white;
}
